import { render, staticRenderFns } from "./LabelingView.vue?vue&type=template&id=6def0f71&scoped=true"
import script from "./LabelingView.vue?vue&type=script&lang=ts"
export * from "./LabelingView.vue?vue&type=script&lang=ts"
import style0 from "./LabelingView.vue?vue&type=style&index=0&id=6def0f71&prod&lang=css"
import style1 from "./LabelingView.vue?vue&type=style&index=1&id=6def0f71&prod&scoped=true&lang=css"
import style2 from "./LabelingView.vue?vue&type=style&index=2&id=6def0f71&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6def0f71",
  null
  
)

export default component.exports